import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Modal, Tabs, Tab, Table } from "react-bootstrap";

import Spinner from "../../components/spinner";
import DataGrid from "../../components/grid";
import {
  invokeApi,
  viewFile,
} from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";

const Orders = () => {
  const [gridData, setGridData] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());
  const [showForm, setShowForm] = useState(false);
  const [rowData, setRowData] = useState(null);

  const columns = [
    {
      field: "id",
      title: "#",
      width: 70,
      filterable: false,
      cell: (props) => <td>{props.dataIndex + 1}</td>,
    },
    {
      field: "order_id",
      title: "Order ID",
      width: 130,
    },
    {
      field: "customer.name",
      title: "Customer Name",
      minWidth: 150,
    },
    {
      field: "vendor.name",
      title: "Vendor Name",
      minWidth: 150,
    },
    {
      field: "order_date",
      title: "Order Date",
      filterable: false,
      width: 180,
      cell: (props) => (
        <td>{moment(props.dataItem.order_date).format("DD/MM/YYYY hh:mm A")}</td>
      ),
    },
    {
      field: "grant_total",
      title: "Total",
      filterable: false,
      width: 100,
      cell: (props) => (
        <td>
          ₹ {props.dataItem.grant_total}
        </td>
      ),
    },
    {
      field: "action",
      title: "Actions",
      filterable: false,
      width: 130,
      cell: (props) => (
        <td>
          <div className="action-coulmn">
            <button
              type="button"
              title="View"
              className="btn btn-soft-info btn-xs waves-effect waves-light me-2"
              onClick={() => onView(props.dataItem)}>
              <i className="mdi mdi-eye"></i>
            </button>
          </div>
        </td>
      ),
    },
  ];

  const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: true,
    previousNext: true,
  };

  useEffect(() => {
    getOrders();
    return () => {
      setGridData([]);
      setEnableFilter(false);
      setShowLoader(false);
    };
  }, []);

  const getOrders = () => {
    setShowLoader(true);
    invokeApi("post", ApiConstants.orders.getPendingOrders, { from: startDate, to: endDate }, true)
      .then((response) => {
        if (response.status_code === 200) {
          setGridData(response.payload?.orders);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const onView = (data) => {
    setRowData(data);
    setShowForm(true);
  };

  const closeModal = () => {
    setRowData(null);
    setShowForm(false);
  };

  const DetailComponent = () => {
    return (
      <div className="container">
        <div className="row pb-3">
          <div className="col-lg-12">

            <Tabs
              defaultActiveKey="order"
              id="order-details"
            >
              <Tab eventKey="order" title="Order Details">
                <div>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="mdi mdi-archive me-1"></i> Order Details
                  </h5>
                  <div className="ps-3">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Order ID</h4>
                          :  <span className="ms-1">{rowData?.order_id}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Service</h4>
                          :  <span className="ms-1">{rowData?.service_name}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Chair Count</h4>
                          :  <span className="ms-1">{rowData?.chair_count}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Item Total</h4>
                          :  <span className="ms-1">₹ {rowData?.item_total}/-</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Service Amount</h4>
                          :  <span className="ms-1">₹ {rowData?.service_amount}/-</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Tax</h4>
                          :  <span className="ms-1">₹ {rowData?.tax_total}/-</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Grant Total</h4>
                          :  <span className="ms-1">₹ {rowData?.grant_total}/-</span>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Order Date</h4>
                          :  <span className="ms-1">{moment(rowData?.order_date).format("DD/MM/YYYY hh:mm A")} </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Customer Collection Time</h4>
                          :  <span className="ms-1">{moment(rowData?.customer_collection_at).format("DD/MM/YYYY hh:mm A")}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Rating</h4>
                          :  <span className="ms-1 d-flex align-items-center">
                            <i className="mdi mdi-star me-1 rating-icon" ></i>
                            <span> {Number(rowData?.rating)?.toFixed(1)}</span>
                          </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="font-13 text-muted" style={{ width: 180 }}>Status</h4>
                          :  <span className="ms-1 badge bg-danger p-1 ">{rowData?.status}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="items" title="Items Details">
                <div>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="mdi mdi-basket me-1"></i> Items Details
                  </h5>
                  <div className="ps-3">
                    <Table hover responsive="xl">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Offer Percentage</th>
                          <th>Offer Price</th>
                          <th>Tax</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowData?.products?.map((item, index) =>
                          <tr key={index}>
                            <td className="align-middle" width="50">{index + 1}</td>
                            <td width="300">
                              <div className="d-flex align-items-center">
                                <img
                                  className="d-flex me-3 rounded-circle avatar-sm"
                                  src={
                                    item?.product_file_path
                                      ? viewFile(item?.product_file_path)
                                      : "/assets/images/placeholder-img.png"
                                  }
                                  alt="DP"
                                />{item?.product_name}
                              </div>
                            </td>
                            <td className="align-middle" width="100">{item?.quantity}</td>
                            <td className="align-middle" width="120">₹ {item?.price}/-</td>
                            <td className="align-middle" width="150">{item?.offer_percentage} %</td>
                            <td className="align-middle" width="120">₹ {item?.offer_price}/-</td>
                            <td className="align-middle" width="120">₹ {item?.tax_amount}/-</td>
                          </tr>
                        )}

                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="customer" title="Customer Details">
                <div>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="mdi mdi-account-circle me-1"></i> Customer Details
                  </h5>
                  <div className="ps-3">
                    <div className="d-flex align-items-center mb-1">
                      <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Customer Name</h4>
                      :  <span className="ms-1">{rowData?.customer?.name}</span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Contact Number</h4>
                      :  <span className="ms-1">{rowData?.customer?.country_code}-{rowData?.customer?.mobile}</span>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="vendor" title="Vendor Details">
                <div>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="mdi mdi-silverware me-1"></i> Vendor Details
                  </h5>
                  <div className="ps-3">
                    <div className="d-flex align-items-center mb-1">
                      <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Vendor Name</h4>
                      :  <span className="ms-1">{rowData?.vendor?.name}</span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Contact Number</h4>
                      :  <span className="ms-1">{rowData?.vendor?.country_code}-{rowData?.vendor?.mobile}</span>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="payment" title="Payment Details">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="mdi mdi-receipt me-1"></i> Receipt Details
                    </h5>
                    <div className="ps-3">
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Amount</h4>
                        :  <span className="ms-1">₹ {rowData?.payment?.amount}/-</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Amount Due</h4>
                        :  <span className="ms-1">₹ {rowData?.payment?.amount_due}/-</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Amount Paid</h4>
                        :  <span className="ms-1">₹ {rowData?.payment?.amount_paid}/-</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Currency</h4>
                        :  <span className="ms-1">{rowData?.payment?.currency}</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Receipt</h4>
                        :  <span className="ms-1">{rowData?.payment?.receipt}</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Status</h4>
                        :  <span className="ms-1">{rowData?.payment?.status}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="mdi mdi-cash me-1"></i> Payment Details
                    </h5>
                    <div className="ps-3">
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Razorpay Order ID</h4>
                        :  <span className="badge bg-info p-1 ms-1">{rowData?.payment?.razorpay_order_id}</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Razorpay Payment ID</h4>
                        :  <span className="badge bg-success p-1 ms-1">{rowData?.payment?.razorpay_payment_id}</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Attempts</h4>
                        :  <span className="ms-1">{rowData?.payment?.attempts}</span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Notes</h4>
                        :  <span className="ms-1">{rowData?.payment?.notes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Orders</h4>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body px-1 px-lg-3">
          <div className="row mb-2">
            <div className="col-12 col-md-3 mb-1">
              <label className="mb-1">Start Date</label>
              <DatePicker
                className="form-control"
                placeholderText="Start Date"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(value) => {
                  setStartDate(value);
                }}
              />
            </div>
            <div className="col-12 col-md-3 mb-1">
              <label className="mb-1">End Date</label>
              <DatePicker
                className="form-control"
                placeholderText="End Date"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(value) => {
                  setEndDate(value);
                }}
              />
            </div>
            <div className="col-12 col-md-3 mb-1 pt-1">
              <button
                type="button"
                className="btn btn-success waves-effect waves-light mt-3"
                onClick={() => { getOrders(); }}>
                <span className="btn-label">
                  <i className="mdi mdi-magnify"></i>
                </span>
                Search
              </button>
            </div>
            <div className="col-12 col-md-3 mb-1 pt-1 text-end">
              <button
                type="button"
                className={`btn waves-effect waves-light mt-3 ${!enableFilter ? "btn-outline-success" : "btn-success"
                  }`}
                onClick={() => {
                  setEnableFilter(!enableFilter);
                }}>
                <i
                  className={`mdi ${enableFilter ? "mdi-filter-off" : "mdi-filter"
                    }`}></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DataGrid
                gridData={gridData}
                columns={columns}
                pagerSettings={pagerSettings}
                skip={0}
                take={10}
                filterable={enableFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={showForm} backdrop="static" keyboard={true}>
        <Modal.Header className="pb-0">
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}></button>
        </Modal.Header>
        <Modal.Body className="py-1">
          <DetailComponent />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Orders;
