import React, { useState, useEffect } from "react";

import { invokeApi } from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";
import Spinner from "../../components/spinner";

const Dashboard = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    getMonthlyOrders();
    getCustomers();
    getvendors();
  }, []);

  const getMonthlyOrders = () => {
    setShowLoader(true);
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    invokeApi("post", ApiConstants.orders.getPendingOrders, { from: firstDay, to: lastDay }, true)
      .then((response) => {
        if (response.status_code === 200) {
          setOrders(response.payload?.orders);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const getCustomers = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.client.get, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setCustomers(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const getvendors = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.vendor.get, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setVendors(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Dashboard</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-xl-4 stretch-card">
          <div className="card bg-gradient-info card-img-holder">
            <div className="card-body">
              <img src="assets/svg/circle.svg" className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3 text-white">Monthly Orders <i className="mdi mdi-bookmark-outline mdi-24px float-end"></i>
              </h4>
              <h2 className="mb-4 text-white">{orders.length + '+'}</h2>
              {/* <h5 className="card-text text-white">Decreased by 10%</h5> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 stretch-card">
          <div className="card bg-gradient-danger card-img-holder">
            <div className="card-body">
              <img src="assets/svg/circle.svg" className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3 text-white">Vendors <i className="mdi mdi-chart-line mdi-24px float-end"></i>
              </h4>
              <h2 className="mb-4 text-white">{vendors.length + '+'} </h2>
              {/* <h5 className="card-text text-white">Increased by 60%</h5> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 stretch-card">
          <div className="card bg-gradient-success card-img-holder">
            <div className="card-body">
              <img src="assets/svg/circle.svg" className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3 text-white">Customers <i className="mdi mdi-diamond mdi-24px float-end"></i>
              </h4>
              <h2 className="mb-4 text-white">{customers.length + '+'}</h2>
              {/* <h5 className="card-text text-white">Increased by 5%</h5> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
