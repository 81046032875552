import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import { invokeApi } from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";

const Login = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    return () => {
      setShowLoader(false);
      setIsSubmitted(false);
    };
  }, []);

  const navigate = useNavigate();

  let SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Please enter email."),
    password: Yup.string().required("Please enter password."),
  });

  const onSubmit = (values) => {
    setShowLoader(true);

    let params = {
      email: values.email,
      password: values.password,
    };

    invokeApi("post", ApiConstants.auth.login, params, false)
      .then((response) => {
        if (response.status_code === 200) {
          localStorage.setItem("user", JSON.stringify(response.payload));
          localStorage.setItem("auth", true);
          navigate("/dashboard");
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-4">
          <div className="card bg-pattern">
            <div className="card-body p-4">
              <div className="text-center w-75 m-auto">
                <div className="auth-logo">
                  <div className="logo logo-dark text-center">
                    <span className="logo-lg">
                      <img src="/assets/images/logo.png" alt="" height="100" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={SignInSchema}
                  onSubmit={(values) => onSubmit(values)}>
                  {({ errors }) => (
                    <Form>
                      <div className="mb-3">
                        <label htmlFor="emailaddress" className="form-label">
                          Email address
                        </label>
                        <Field
                          className={`form-control ${
                            errors.email && isSubmitted ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <div className="invalid-feedback">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            className={`form-control ${
                              errors.password && isSubmitted ? "is-invalid" : ""
                            }`}
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                          />

                          <div
                            className={`input-group-text ${
                              errors.password && isSubmitted ? "is-invalid" : ""
                            }`}
                            data-password="false"
                            role="button">
                            <span className="password-eye"></span>
                          </div>
                          <ErrorMessage name="password">
                            {(msg) => (
                              <div className="invalid-feedback">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox-signin"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox-signin">
                            Remember me
                          </label>
                        </div>
                      </div>

                      <div className="text-center d-grid">
                        <button
                          className="btn btn-primary auth-btn"
                          type="submit"
                          onClick={() => setIsSubmitted(true)}>
                          Log In
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {/* <div className="row my-2">
              <div className="col-12 text-center">
                <p>
                  <Link to="/forgot-password" className="text-dark-50 ms-1">
                    Forgot your password?
                  </Link>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
