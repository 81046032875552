const ApiConstants = {
  auth: {
    login: "/api/auth/adminlogin",
    refreshtoken: "/api/auth/refresh",
  },
  admin: {
    profile: "/api/auth/profile",
    updateProfile: "/api/admin/updateadminprofile",
    get: "/api/admin/get",
    create: "/api/auth/registeradmin",
    update: "/api/admin/update",
    delete: "/api/admin/delete",
    changepassword: "/api/auth/changepassword"
  },
  file: {
    upload: "/api/file/upload",
    download: "/api/file/download",
    view: "/api/file/view",
  },
  config: {
    country: {
      get: "/api/country/get",
      create: "/api/country/create",
      update: "/api/country/update",
      delete: "/api/country/delete",
    },
    region: {
      get: "/api/region/get",
      create: "/api/region/create",
      update: "/api/region/update",
      delete: "/api/region/delete",
    },
    area: {
      get: "/api/area/get",
      create: "/api/area/create",
      update: "/api/area/update",
      delete: "/api/area/delete",
    },
  },
  categories: {
    get: "/api/category/getall",
    create: "/api/category/create",
    update: "/api/category/update",
    delete: "/api/category/delete",
  },
  products: {
    getallproducts: "/api/product/getall",
    create: "/api/product/create",
    update: "/api/product/update",
    delete: "/api/product/delete",
  },
  services: {
    get: "/api/service/get",
    create: "/api/service/create",
    update: "/api/service/update",
    delete: "/api/service/delete",
  },
  tax: {
    get: "/api/tax/get",
    create: "/api/tax/create",
    update: "/api/tax/update",
    delete: "/api/tax/delete",
  },
  banner: {
    get: "/api/banner/get",
    create: "/api/banner/create",
    update: "/api/banner/update",
    delete: "/api/banner/delete",
  },
  amenities: {
    get: "/api/amenities/get",
    create: "/api/amenities/create",
    update: "/api/amenities/update",
    delete: "/api/amenities/delete",
  },
  vendor: {
    get: "/api/vendor/getall",
  },
  client: {
    get: "/api/client/getall",
  },
  orders: {
    getPendingOrders: "/api/order/allpendingorders",
  },
  settings: {
    getCustomerAppSettings: "/api/mobileversion/getcustomerapp",
    setCustomerAppSettings: "/api/mobileversion/setcustomerapp",
    getVendorAppSettings: "/api/mobileversion/getvendorapp",
    setVendorAppSettings: "/api/mobileversion/setvendorapp",
  }
};

export default ApiConstants;
