import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import DataGrid from "../../components/grid";
import { invokeApi } from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";

const Tax = () => {
  const [gridData, setGridData] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showConfirmDelete, setshowConfirmDelete] = useState(false);

  const columns = [
    {
      field: "id",
      title: "#",
      width: 70,
      filterable: false,
      cell: (props) => <td>{props.dataIndex + 1}</td>,
    },
    {
      field: "name",
      title: "Name",
      minWidth: 200,
    },
    {
      field: "percentage",
      title: "Percentage",
      width: 100,
    },
    {
      field: "action",
      title: "Actions",
      filterable: false,
      width: 130,
      cell: (props) => (
        <td>
          <div className="action-coulmn">
            <button
              type="button"
              title="Edit"
              className="btn btn-soft-info btn-xs waves-effect waves-light me-2"
              onClick={() => onEdit(props.dataItem)}>
              <i className="mdi mdi-pencil-outline"></i>
            </button>
            <button
              type="button"
              title="Delete"
              className="btn btn-soft-danger btn-xs waves-effect waves-light"
              onClick={() => onDelete(props.dataItem)}>
              <i className="mdi mdi-delete"></i>
            </button>
          </div>
        </td>
      ),
    },
  ];

  const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: true,
    previousNext: true,
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter Name"),
    percentage: Yup.string()
      .required("Please enter Percentage")
      .matches(/^[0-9]*$/, "Please enter a valid percentage"),
  });

  useEffect(() => {
    getTax();
    return () => {
      setGridData([]);
      setEnableFilter(false);
      setShowLoader(false);
      setShowForm(false);
      setIsSubmitted(false);
      setRowData(null);
      setshowConfirmDelete(false);
    };
  }, []);

  const getTax = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.tax.get, null, false)
      .then((response) => {
        if (response.status_code === 200) {
          setGridData(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const onSubmit = (values) => {
    setShowLoader(true);
    let params = { name: values.name, percentage: values.percentage };
    if (rowData) {
      params.id = rowData.id;
      updateItem(params);
    } else {
      createItem(params);
    }
  };

  const createItem = (params) => {
    invokeApi("post", ApiConstants.tax.create, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 201) {
          closeModal();
          getTax();
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const updateItem = (params) => {
    invokeApi("put", ApiConstants.tax.update, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          closeModal();
          getTax();
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const deleteItem = () => {
    setShowLoader(true);
    let params = { id: rowData.id };
    invokeApi("delete", ApiConstants.tax.delete, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          closeModal();
          getTax();
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        closeModal();
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const onEdit = (data) => {
    setRowData(data);
    setShowForm(true);
  };

  const onDelete = (data) => {
    setRowData(data);
    setshowConfirmDelete(true);
  };

  const closeModal = () => {
    setRowData(null);
    setShowForm(false);
    setshowConfirmDelete(false);
    setIsSubmitted(false);
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Tax</h4>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body px-1 px-lg-3">
          <div className="row mb-2">
            <div className="col-12 mb-1 text-end">
              <button
                type="button"
                className={`btn waves-effect waves-light me-2 ${
                  !enableFilter ? "btn-outline-success" : "btn-success"
                }`}
                onClick={() => {
                  setEnableFilter(!enableFilter);
                }}>
                <i
                  className={`mdi ${
                    enableFilter ? "mdi-filter-off" : "mdi-filter"
                  }`}></i>
              </button>
              <button
                type="button"
                className="btn btn-success waves-effect waves-light"
                onClick={() => {
                  setShowForm(true);
                }}>
                <span className="btn-label">
                  <i className="mdi mdi-plus"></i>
                </span>
                Add Tax
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DataGrid
                gridData={gridData}
                columns={columns}
                pagerSettings={pagerSettings}
                skip={0}
                take={5}
                filterable={enableFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="md" show={showForm} backdrop="static" keyboard={true}>
        <Modal.Header>
          <h4 className="modal-title">{`${rowData ? "Update" : "Add"} Tax`}</h4>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}></button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: rowData?.name ? rowData.name : "",
              percentage: rowData?.percentage ? rowData.percentage : "",
            }}
            validationSchema={FormSchema}
            onSubmit={(values) => onSubmit(values)}>
            {({ errors }) => (
              <Form>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <label>Name</label>
                      <Field
                        type="text"
                        className={`form-control ${
                          errors.name && isSubmitted ? "is-invalid" : ""
                        }`}
                        placeholder="Name"
                        name="name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 mt-2">
                      <label>Percentage</label>
                      <Field
                        type="text"
                        className={`form-control ${
                          errors.percentage && isSubmitted ? "is-invalid" : ""
                        }`}
                        placeholder="Percentage"
                        name="percentage"
                      />
                      <ErrorMessage name="percentage">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 mt-2">
                      <label>Comment</label>
                      <Field name="comment">
                        {({ field }) => {
                          return (
                            <textarea
                              className="form-control"
                              placeholder="Comments"
                              value={field.value}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage name="name">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 my-2 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary waves-effect waves-light mt-3 me-2"
                        onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-blue waves-effect waves-light mt-3"
                        onClick={() => setIsSubmitted(true)}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showConfirmDelete}
        backdrop="static"
        keyboard={true}>
        <Modal.Header>
          <h5 className="card-title m-0">Confirm Action</h5>
        </Modal.Header>
        <Modal.Body>
          <div> Are you sure that to delete this? </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-outline-primary me-2"
              onClick={closeModal}>
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                deleteItem();
              }}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Tax;
