import React, { Fragment } from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import AdminLayout from "./layouts/AdminLayout";
import BoxLayout from "./layouts/BoxLayout";

import AuthGuard from "./hooks/useAuth";
// import { userRoles } from "./enums/UserRoles";

import Error404 from "./views/errors/404";

import Login from "./views/login";
import Dashboard from "./views/dashboard";
import Countries from "./views/countries";
import Regions from "./views/regions";
import Areas from "./views/areas";
import Categories from "./views/categories";
import Products from "./views/products";
import Services from "./views/services";
import Tax from "./views/tax";
import Banners from "./views/banners";
import Profile from "./views/profile";
import AdminUsers from "./views/users/admin";
import Amenities from "./views/amenities";
import Vendors from "./views/vendors";
import Customers from "./views/customers";
import Orders from "./views/orders";
import Settings from "./views/settings";
import ChangePassword from "./views/change-password";

export const renderRoutes = (routes = []) => (
  <Routes>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          element={
            <Guard {...(route.guard ? { permissions: route.permissions } : {})}>
              <Layout>
                {route.routes ? renderRoutes(route.routes) : <Component />}
              </Layout>
            </Guard>
          }
        />
      );
    })}
  </Routes>
);

const routes = [
  {
    exact: true,
    path: "/",
    layout: AdminLayout,
    component: () => <Navigate to="/dashboard" />,
  },
  {
    exact: true,
    path: "/404",
    component: Error404,
  },
  {
    exact: true,
    path: "/login",
    layout: BoxLayout,
    component: Login,
  },
  {
    exact: true,
    path: "/dashboard",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Dashboard,
  },
  {
    exact: true,
    path: "/countries",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Countries,
  },
  {
    exact: true,
    path: "/regions/:countryName/:countryId",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Regions,
  },
  {
    exact: true,
    path: "/areas/:regionName/:regionId",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Areas,
  },
  {
    exact: true,
    path: "/categories",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Categories,
  },
  {
    exact: true,
    path: "/products",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Products,
  },
  {
    exact: true,
    path: "/services",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Services,
  },
  {
    exact: true,
    path: "/tax",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Tax,
  },
  {
    exact: true,
    path: "/banners",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Banners,
  },
  {
    exact: true,
    path: "/profile",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Profile,
  },
  {
    exact: true,
    path: "/users/admin-users",
    layout: AdminLayout,
    guard: AuthGuard,
    component: AdminUsers,
  },
  {
    exact: true,
    path: "/amenities",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Amenities,
  },
  {
    exact: true,
    path: "/users/vendors",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Vendors,
  },
  {
    exact: true,
    path: "/users/customers",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Customers,
  },
  {
    exact: true,
    path: "/orders",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Orders,
  },
  {
    exact: true,
    path: "/settings",
    layout: AdminLayout,
    guard: AuthGuard,
    component: Settings,
  },
  {
    exact: true,
    path: "/change-password",
    layout: AdminLayout,
    guard: AuthGuard,
    component: ChangePassword,
  },
  {
    path: "*",
    exact: true,
    component: Error404,
  },
];

export default routes;
