import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";

import routes, { renderRoutes } from "./routes";

const App = () => {
  useEffect(() => {
    appendScript(process.env.PUBLIC_URL + "/assets/js/vendor.min.js");
    return () => {
      let elem = document.getElementById("vendor-js");
      elem.parentNode.removeChild(elem);
    };
  }, []);

  const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    script.id = "vendor-js";
    script.defer = true;
    document.body.appendChild(script);
  };

  return (
    <React.Fragment>
      <Router>{renderRoutes(routes)}</Router>
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
