import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import { invokeApi } from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";

const ChangePassword = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const FormSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Please enter current password"),
    newPassword: Yup.string().required("Please enter new password"),
    confirmPassword: Yup.string()
                        .required("Please confirm password")
                        .oneOf([Yup.ref("newPassword")], "Passwords are not matching"),
  });

  const onSubmit = async (values) => {
    setShowLoader(true);
    let params = {
      new_password: values.newPassword
    };
    invokeApi("post", ApiConstants.admin.changepassword, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };


  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Change Password</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <div className="row mt-2">
                <Formik
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={FormSchema}
                  onSubmit={(values) => onSubmit(values)}>
                  {({ errors }) => (
                    <Form>
                      <div className="col-12 col-md-7 offset-md-2 mb-3">
                        <label htmlFor="currentPassword" className="form-label">
                          Current Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            className={`form-control ${errors.currentPassword && isSubmitted ? "is-invalid" : ""
                              }`}
                            type="password"
                            name="currentPassword"
                            placeholder="Enter current password"
                          />

                          <div
                            className={`input-group-text ${errors.currentPassword && isSubmitted ? "is-invalid" : ""
                              }`}
                            data-password="false"
                            role="button">
                            <span className="password-eye"></span>
                          </div>
                          <ErrorMessage name="currentPassword">
                            {(msg) => (
                              <div className="invalid-feedback">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12 col-md-7 offset-md-2 mb-3">
                        <label htmlFor="newPassword" className="form-label">
                          New Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            className={`form-control ${errors.newPassword && isSubmitted ? "is-invalid" : ""
                              }`}
                            type="password"
                            name="newPassword"
                            placeholder="Enter new password"
                          />
                          <div
                            className={`input-group-text ${errors.newPassword && isSubmitted ? "is-invalid" : ""
                              }`}
                            data-password="false"
                            role="button">
                            <span className="password-eye"></span>
                          </div>
                          <ErrorMessage name="newPassword">
                            {(msg) => (
                              <div className="invalid-feedback">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12 col-md-7 offset-md-2 mb-3">
                        <label htmlFor="password" className="form-label">
                          Confirm Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            className={`form-control ${errors.confirmPassword && isSubmitted ? "is-invalid" : ""
                              }`}
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm password"
                          />

                          <div
                            className={`input-group-text ${errors.confirmPassword && isSubmitted ? "is-invalid" : ""
                              }`}
                            data-password="false"
                            role="button">
                            <span className="password-eye"></span>
                          </div>
                          <ErrorMessage name="confirmPassword">
                            {(msg) => (
                              <div className="invalid-feedback">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12 col-md-7 offset-md-2 my-4 text-center d-grid">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => setIsSubmitted(true)}>
                          Change Password
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>


    </React.Fragment>
  );
};

export default ChangePassword;
