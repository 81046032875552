import { toast, Bounce } from "react-toastify";

export const toastService = {
  success: (msg) => {
    toast.dismiss();
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      transition: Bounce,
    });
  },
  error: (msg) => {
    toast.dismiss();
    if (!msg) msg = "Something went wrong. Please try after some time";
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      transition: Bounce,
    });
  },
};
