import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

import Spinner from "../../components/spinner";
import DataGrid from "../../components/grid";
import { invokeApi, viewFile } from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";

const Vendors = () => {
  const [gridData, setGridData] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showConfirmDelete, setshowConfirmDelete] = useState(false);

  const columns = [
    {
      field: "id",
      title: "#",
      width: 70,
      filterable: false,
      cell: (props) => <td>{props.dataIndex + 1}</td>,
    },
    {
      field: "image_id",
      title: "Profile Image",
      width: 120,
      filterable: false,
      cell: (props) => (
        <td>
          <div className="text-center">
            <img
              src={
                props.dataItem.profile_thumb_file_path
                  ? viewFile(props.dataItem.profile_thumb_file_path)
                  : "/assets/images/placeholder-img.png"
              }
              className="list-thumbnail"
              alt=""
            />
          </div>
        </td>
      ),
    },
    {
      field: "name",
      title: "Name",
      width: 200,
    },
    {
      field: "email",
      title: "Email",
      minWidth: 200,
    },
    {
      field: "mobile",
      title: "Mobile",
      minWidth: 200,
      cell: (props) => (
        <td>
          <div>{props.dataItem.country_code} {props.dataItem.mobile}</div>
        </td>
      )
    },
    {
      field: "action",
      title: "Actions",
      filterable: false,
      width: 130,
      cell: (props) => (
        <td>
          <div className="action-coulmn">
            <button
              type="button"
              title="View"
              className="btn btn-soft-info btn-xs waves-effect waves-light me-2"
              onClick={() => onView(props.dataItem)}>
              <i className="mdi mdi-eye"></i>
            </button>
            <button
              type="button"
              title="Delete"
              className="btn btn-soft-danger btn-xs waves-effect waves-light"
              onClick={() => onDelete(props.dataItem)}>
              <i className="mdi mdi-delete"></i>
            </button>
          </div>
        </td>
      ),
    },
  ];

  const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: true,
    previousNext: true,
  };

  useEffect(() => {
    getvendors();
    return () => {
      setGridData([]);
      setEnableFilter(false);
      setShowLoader(false);
      setShowForm(false);
      setRowData(null);
      setshowConfirmDelete(false);
    };
  }, []);

  const getvendors = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.vendor.get, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setGridData(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };


  const deleteItem = () => {
    closeModal();

    // setShowLoader(true);
    // let params = { id: rowData.id };
    // invokeApi("delete", ApiConstants.amenities.delete, params, true)
    //   .then((response) => {
    //     setShowLoader(false);
    //     if (response && response.status_code === 200) {
    //       closeModal();
    //       getvendors();
    //       toastService.success(response.message);
    //     } else {
    //       toastService.error(response.message);
    //     }
    //   })
    //   .catch((error) => {
    //     setShowLoader(false);
    //     closeModal();
    //     toastService.error("Something went wrong. Please try again later.");
    //   });
  };

  const onView = (data) => {
    setRowData(data);
    setShowForm(true);
  };

  const onDelete = (data) => {
    setRowData(data);
    setshowConfirmDelete(true);
  };

  const closeModal = () => {
    setRowData(null);
    setShowForm(false);
    setshowConfirmDelete(false);
  };

  const DetailComponent = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-start mb-3">
                  <img
                    className="d-flex me-3 rounded-circle avatar-lg"
                    src={
                      rowData?.profile_thumb_file_path
                        ? viewFile(rowData?.profile_thumb_file_path)
                        : "/assets/images/placeholder-img.png"
                    }
                    alt="DP"
                  />
                  <div className="w-100">
                    <h4 className="mt-0 mb-1">{rowData?.name}</h4>
                    <p className="d-flex align-items-center">
                      <i className="mdi mdi-star me-1 rating-icon" ></i>
                      <span> {Number(rowData?.rating)?.toFixed(1)}</span>
                    </p>
                  </div>
                </div>

                <h5 className="mb-3 mt-4 text-uppercase bg-light p-2">
                  <i className="mdi mdi-silverware me-1"></i> Vendor
                  Information
                </h5>
                <div className="ps-3">
                <div className="d-flex align-items-start mb-2">
                    <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Address</h4>
                    :  <span className="ms-1" style={{maxWidth: 360}}>{rowData?.address_line1}, {rowData?.address_line2}, {rowData?.area_name}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="font-13 text-muted" style={{ width: 180 }}>Email</h4>
                    :  <span className="ms-1"> <a href={`mailto:${rowData?.email}`}>{rowData?.email}</a></span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="font-13 text-muted" style={{ width: 180 }}>Mobile</h4>
                    : {rowData?.mobile && <span className="ms-1"> <a href={`tel:${rowData?.country_code + rowData?.mobile}`}>{rowData?.country_code} - {rowData?.mobile}</a></span>}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="font-13 text-muted" style={{ width: 180 }}>Spoc Mobile</h4>
                    : {rowData?.spoc_mobile && <span className="ms-1"> <a href={`tel:${rowData?.spoc_country_code + rowData?.spoc_mobile}`}>{rowData?.spoc_country_code} - {rowData?.spoc_mobile}</a></span>}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="font-13 text-muted" style={{ width: 180 }}>Opening at</h4>
                    : {rowData?.opening_at && <span className="ms-1"> {moment(rowData?.opening_at).format("HH:mm A")} </span>}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="font-13 text-muted" style={{ width: 180 }}>Clossing at</h4>
                    : {rowData?.closing_at && <span className="ms-1">{moment(rowData?.closing_at).format("HH:mm A")}</span>}
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <h4 className="font-13 text-muted mt-1" style={{ width: 180 }}>Description</h4>
                    : <span className="ms-1" style={{maxWidth: 360}}>{rowData?.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-start mb-3">
                  {rowData?.latitude && rowData?.longitude && <iframe title="location-map" src={`https://www.google.com/maps?q=${rowData?.latitude},${rowData?.longitude}&output=embed&z=126`} width="600" height="450" style={{ border: 0, zIndex: 1 }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Vendors</h4>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body px-1 px-lg-3">
          <div className="row mb-2">
            <div className="col-12 mb-1 text-end">
              <button
                type="button"
                className={`btn waves-effect waves-light me-2 ${!enableFilter ? "btn-outline-success" : "btn-success"
                  }`}
                onClick={() => {
                  setEnableFilter(!enableFilter);
                }}>
                <i
                  className={`mdi ${enableFilter ? "mdi-filter-off" : "mdi-filter"
                    }`}></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DataGrid
                gridData={gridData}
                columns={columns}
                pagerSettings={pagerSettings}
                skip={0}
                take={5}
                filterable={enableFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={showForm} backdrop="static" keyboard={true}>
        <Modal.Header className="pb-0">
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}></button>
        </Modal.Header>
        <Modal.Body className="py-1">
          <DetailComponent />
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showConfirmDelete}
        backdrop="static"
        keyboard={true}>
        <Modal.Header>
          <h5 className="card-title m-0">Confirm Action</h5>
        </Modal.Header>
        <Modal.Body>
          <div> Are you sure that to delete this? </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-outline-primary me-2"
              onClick={closeModal}>
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                deleteItem();
              }}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Vendors;
