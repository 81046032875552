import React from "react";

import loader from "./loader.svg";

const Spinner = () => {
  const styles = {
    container: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
      background: "rgba(255,255,255, 0.3)",
    },
    loaderImg: {
      width: 100,
    },
  };

  return (
    <React.Fragment>
      <div style={styles.container}>
        <img src={loader} style={styles.loaderImg} alt="Loading..." />
      </div>
    </React.Fragment>
  );
};

export default Spinner;
