import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import {
  invokeApi,
  invokeFileUpload,
  viewFile,
} from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";
import AppConfig from "../../config/appConfig";

const Profile = (props) => {
  const [profileData, setProfileData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [previewImg, setPreviewImg] = useState(null);

  const FILE_SIZE = AppConfig.MAX_UPLOAD_SIZE;
  const SUPPORTED_FORMATS = AppConfig.SUPPORTED_FORMATS;

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter Name"),
    email: Yup.string().required("Please enter Email"),
    mobile: Yup.string().required("Please enter Mobile number"),
    imgFile: Yup.mixed()
      .test("fileSize", "Exceeds maximum file size (Max 50MB)", (value) => {
        if (value) {
          return value.size <= FILE_SIZE;
        }
        return true;
      })
      .test("fileFormat", "Unsupported Format", (value) => {
        if (value) {
          let regex = /(?:\.([^.]+))?$/;
          let ext = regex.exec(value.name)[1];
          return SUPPORTED_FORMATS.includes(ext?.toLowerCase());
        }
        return true;
      }),
  });

  useEffect(() => {
    getProfile();
    return () => {
      setProfileData(null);
      setShowLoader(false);
      setShowForm(false);
      setPreviewImg(null);
    };
  }, []);

  const getProfile = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.admin.profile, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setProfileData(response.payload);
          let userData = JSON.parse(localStorage.getItem("user"));
          if (
            userData.user_details.profileImage !==
            response.payload?.profile_image
          ) {
            userData.user_details.profileImage =
              response.payload?.profile_image;
            localStorage.setItem("user", JSON.stringify(userData));
          }
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const uploadFiles = (data) =>
    new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", data);

      return invokeFileUpload(formData)
        .then((response) => {
          if (response.status_code === 201) resolve(response);
          else {
            reject(response);
            toastService.error(response.message);
          }
        })
        .catch((error) => {
          reject(error);
          toastService.error("Something went wrong. Please try again later.");
        });
    });

  const onFileChange = (event) => {
    let file = event.target.files[0];
    if (file) {
      let regex = /(?:\.([^.]+))?$/;
      let ext = regex.exec(file.name)[1];

      if (file.size >= FILE_SIZE) {
        return;
      }

      if (!SUPPORTED_FORMATS.includes(ext?.toLowerCase())) {
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setPreviewImg(reader.result);
      };
    }
  };

  const onSubmit = async (values) => {
    setShowLoader(true);
    let params = {
      name: values.name,
      email: values.email,
      country_code: "+91",
      mobile: values.mobile,
    };

    if (values.imgFile) {
      await uploadFiles(values.imgFile)
        .then((response) => {
          params.image_id = response.payload.file_id;
        })
        .catch((error) => { });
    }

    if (!values.imgFile) {
      params.image_id = profileData?.profile_image?.id;
    }

    invokeApi("put", ApiConstants.admin.updateProfile, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          closeModal();
          toastService.success(response.message);
          getProfile();
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const closeModal = () => {
    setShowForm(false);
    setIsSubmitted(false);
    setPreviewImg(null);
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Profile</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-start mb-3">
                <img
                  className="d-flex me-3 rounded-circle avatar-lg"
                  src={
                    profileData?.profile_image
                      ? viewFile(profileData?.profile_image)
                      : "/assets/images/avatar.jpg"
                  }
                  alt="DP"
                />
                <div className="w-100">
                  <h4 className="mt-0 mb-1">{profileData?.name}</h4>
                  <p className="text-muted">Super Admin</p>

                  <Link
                    to="#"
                    className="btn btn-xs btn-info me-1 disabled"
                    aria-disabled="true"
                    // onClick={() => setShowForm(true)}
                    >
                    Edit
                  </Link>

                  <Link to="/change-password" className="btn- btn-xs btn-secondary">
                    Change Password
                  </Link>
                </div>
              </div>

              <h5 className="mb-3 mt-4 text-uppercase bg-light p-2">
                <i className="mdi mdi-account-circle me-1"></i> Personal
                Information
              </h5>
              <div className="d-flex align-items-center">
                <h4 className="font-13 text-muted text-uppercase" style={{ width: 100 }}>Email</h4>
                <span> : {profileData?.email}</span>
              </div>
              <div className="d-flex align-items-center">
                <h4 className="font-13 text-muted text-uppercase mb-1" style={{ width: 100 }}>Mobile</h4>
                <span> : {profileData?.country_code} {profileData?.mobile}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="md" show={showForm} backdrop="static" keyboard={true}>
        <Modal.Header>
          <h4 className="modal-title">Update Profile</h4>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}></button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: profileData?.name ? profileData.name : "",
              email: profileData?.email ? profileData?.email : "",
              mobile: profileData?.mobile ? profileData?.mobile : "",
              imgFile: undefined,
            }}
            validationSchema={FormSchema}
            onSubmit={(values) => onSubmit(values)}>
            {({ errors, setFieldValue }) => (
              <Form>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-center mb-3 position-relative">
                        <img
                          className="d-flex rounded-circle avatar-xl"
                          src={
                            previewImg
                              ? previewImg
                              : profileData?.profile_image
                                ? viewFile(profileData?.profile_image)
                                : "../assets/images/avatar.jpg"
                          }
                          alt="DP"
                        />

                        <input
                          type="file"
                          className={`form-control ${errors.imgFile && isSubmitted ? "is-invalid" : ""
                            }`}
                          id="display-pic-input"
                          accept="image/jpg, image/jpeg, image/png"
                          onChange={(event) => {
                            onFileChange(event);
                            setFieldValue(
                              "imgFile",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <label htmlFor="display-pic-input">
                          <span className="dp-edit-icon">
                            <i className="feather icon-camera" />
                          </span>
                        </label>
                      </div>
                      {errors.imgFile && isSubmitted && (
                        <div className="text-danger text-center">
                          {errors.imgFile}
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <label>Name</label>
                      <Field
                        type="text"
                        className={`form-control ${errors.name && isSubmitted ? "is-invalid" : ""
                          }`}
                        placeholder="Name"
                        name="name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 mt-2">
                      <label>Email</label>
                      <Field
                        type="text"
                        className={`form-control ${errors.email && isSubmitted ? "is-invalid" : ""
                          }`}
                        placeholder="Email"
                        name="email"
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 mt-2">
                      <label>Mobile Number</label>
                      <Field
                        type="text"
                        className={`form-control ${errors.mobile && isSubmitted ? "is-invalid" : ""
                          }`}
                        placeholder="Mobile Number"
                        name="mobile"
                      />
                      <ErrorMessage name="mobile">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 my-2 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary waves-effect waves-light mt-3 me-2"
                        onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-blue waves-effect waves-light mt-3"
                        onClick={() => setIsSubmitted(true)}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Profile;
