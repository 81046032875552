import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import Spinner from "../../components/spinner";
import DataGrid from "../../components/grid";
import { invokeApi} from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";

const Customers = () => {
  const [gridData, setGridData] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showConfirmDelete, setshowConfirmDelete] = useState(false);

  const columns = [
    {
      field: "id",
      title: "#",
      width: 70,
      filterable: false,
      cell: (props) => <td>{props.dataIndex + 1}</td>,
    },
    {
      field: "name",
      title: "Name",
      width: 200,
    },
    {
      field: "email",
      title: "Email",
      minWidth: 200,
    },
    {
      field: "mobile",
      title: "Mobile",
      minWidth: 200,
      cell: (props) => (
        <td>
          <div>{props.dataItem.country_code} {props.dataItem.mobile}</div>
        </td>
      )
    },
    {
      field: "action",
      title: "Actions",
      filterable: false,
      width: 100,
      cell: (props) => (
        <td>
          <div className="action-coulmn">
            <button
              type="button"
              title="Delete"
              className="btn btn-soft-danger btn-xs waves-effect waves-light"
              onClick={() => onDelete(props.dataItem)}>
              <i className="mdi mdi-delete"></i>
            </button>
          </div>
        </td>
      ),
    },
  ];

  const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: true,
    previousNext: true,
  };

  useEffect(() => {
    getCustomers();
    return () => {
      setGridData([]);
      setEnableFilter(false);
      setShowLoader(false);
      setRowData(null);
      setshowConfirmDelete(false);
    };
  }, []);

  const getCustomers = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.client.get, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setGridData(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const deleteItem = () => {
    closeModal();

    // setShowLoader(true);
    // let params = { id: rowData.id };
    // invokeApi("delete", ApiConstants.amenities.delete, params, true)
    //   .then((response) => {
    //     setShowLoader(false);
    //     if (response && response.status_code === 200) {
    //       closeModal();
    //       getvendors();
    //       toastService.success(response.message);
    //     } else {
    //       toastService.error(response.message);
    //     }
    //   })
    //   .catch((error) => {
    //     setShowLoader(false);
    //     closeModal();
    //     toastService.error("Something went wrong. Please try again later.");
    //   });
  };

  const onDelete = (data) => {
    setRowData(data);
    setshowConfirmDelete(true);
  };

  const closeModal = () => {
    setRowData(null);
    setshowConfirmDelete(false);
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Customers</h4>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body px-1 px-lg-3">
          <div className="row mb-2">
            <div className="col-12 mb-1 text-end">
              <button
                type="button"
                className={`btn waves-effect waves-light me-2 ${!enableFilter ? "btn-outline-success" : "btn-success"
                  }`}
                onClick={() => {
                  setEnableFilter(!enableFilter);
                }}>
                <i
                  className={`mdi ${enableFilter ? "mdi-filter-off" : "mdi-filter"
                    }`}></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DataGrid
                gridData={gridData}
                columns={columns}
                pagerSettings={pagerSettings}
                skip={0}
                take={5}
                filterable={enableFilter}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={showConfirmDelete}
        backdrop="static"
        keyboard={true}>
        <Modal.Header>
          <h5 className="card-title m-0">Confirm Action</h5>
        </Modal.Header>
        <Modal.Body>
          <div> Are you sure that to delete this? </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-outline-primary me-2"
              onClick={closeModal}>
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                deleteItem();
              }}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Customers;
