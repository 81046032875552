import React, { useEffect } from "react";

const BoxLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add("authentication-bg");
    document.body.classList.add("authentication-bg-pattern");

    appendScript(process.env.PUBLIC_URL + "/assets/js/app.js");

    return () => {
      document.body.classList.remove("authentication-bg");
      document.body.classList.remove("authentication-bg-pattern");

      let elem = document.getElementById("app-js");
      elem.parentNode.removeChild(elem);
    };
  }, []);

  const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    script.id = "app-js";
    script.defer = true;
    document.body.appendChild(script);
  };

  return (
    <React.Fragment>
      <div className="account-pages mt-5 mb-5">
        <div className="container">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default BoxLayout;
