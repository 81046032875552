import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import Spinner from "../../components/spinner";
import DataGrid from "../../components/grid";
import {
  invokeApi,
  invokeFileUpload,
  viewFile,
} from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";
import AppConfig from "../../config/appConfig";

const Products = () => {
  const [gridData, setGridData] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showConfirmDelete, setshowConfirmDelete] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [taxList, setTaxList] = useState([]);

  const columns = [
    {
      field: "id",
      title: "#",
      width: 70,
      filterable: false,
      cell: (props) => <td>{props.dataIndex + 1}</td>,
    },
    {
      field: "thumn_image",
      title: "Image",
      filterable: false,
      width: 100,
      cell: (props) => (
        <td>
          <div>
            <img
              src={
                props.dataItem.thumb_image
                  ? viewFile(props.dataItem.thumb_image.file_path)
                  : "/assets/images/placeholder-img.png"
              }
              className="list-thumbnail"
              alt=""
            />
          </div>
        </td>
      ),
    },
    {
      field: "name",
      title: "Product Name",
      width: 220,
    },
    {
      field: "categories",
      title: "Categories",
      filterable: false,
      minWidth: 200,
      cell: (props) => (
        <td>
          <div>
            {
              props.dataItem.categories.map((i, key) => {
                return (<span key={key} className="badge badge-primary">{i.name}</span>)
              })
            }
          </div>
        </td>
      ),
    },
    {
      field: "action",
      title: "Actions",
      filterable: false,
      width: 130,
      cell: (props) => (
        <td>
          <div className="action-coulmn">
            <button
              type="button"
              title="Edit"
              className="btn btn-soft-info btn-xs waves-effect waves-light me-2"
              onClick={() => onEdit(props.dataItem)}>
              <i className="mdi mdi-pencil-outline"></i>
            </button>
            <button
              type="button"
              title="Delete"
              className="btn btn-soft-danger btn-xs waves-effect waves-light"
              onClick={() => onDelete(props.dataItem)}>
              <i className="mdi mdi-delete"></i>
            </button>
          </div>
        </td>
      ),
    },
  ];

  const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: true,
    previousNext: true,
  };

  const FILE_SIZE = AppConfig.MAX_UPLOAD_SIZE;
  const THUMB_FILE_SIZE = AppConfig.MAX_UPLOAD_SIZE_THUMB;
  const SUPPORTED_FORMATS = AppConfig.SUPPORTED_FORMATS;

  const FormSchema = Yup.object().shape({
    product_name: Yup.string().required("Please enter Product Name"),
    category_list: Yup.mixed()
      .test("required", "Please select at least one Category", (value) => {
        return value.length >= 1 ? true : false;
      }),
    imgFile: Yup.mixed()
      .test("required", "Please select an Image", (value, context) => {
        if (context.parent.image_id)
          return true
        else
          return true ? value : false;
      })
      .test("fileSize", "Exceeds maximum file size (Max 50MB)", (value) => {
        if (value) {
          return value.size <= FILE_SIZE;
        }
        return true;
      })
      .test("fileFormat", "Unsupported Format", (value) => {
        if (value) {
          let regex = /(?:\.([^.]+))?$/;
          let ext = regex.exec(value.name)[1];
          return SUPPORTED_FORMATS.includes(ext?.toLowerCase());
        }
        return true;
      }),
    thumbImgFile: Yup.mixed()
      .test("required", "Please select a Thumb image", (value, context) => {
        if (context.parent.thumb_image_id)
          return true
        else
          return true ? value : false;
      })
      .test("fileSize", "Exceeds maximum file size (Max 1MB)", (value) => {
        if (value) {
          return value.size <= THUMB_FILE_SIZE;
        }
        return true;
      })
      .test("fileFormat", "Unsupported Format", (value) => {
        if (value) {
          let regex = /(?:\.([^.]+))?$/;
          let ext = regex.exec(value.name)[1];
          return SUPPORTED_FORMATS.includes(ext?.toLowerCase());
        }
        return true;
      }),
    description: Yup.string(),
  });

  useEffect(() => {
    getProducts();
    getCategoriesList();
    return () => {
      setGridData([]);
      setEnableFilter(false);
      setShowLoader(false);
      setShowForm(false);
      setIsSubmitted(false);
      setRowData(null);
      setshowConfirmDelete(false);
      setCategoriesList([]);
      setTaxList([]);
    };
  }, []);

  const getProducts = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.products.getallproducts, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setGridData(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const uploadFiles = (data) =>
    new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", data);

      return invokeFileUpload(formData)
        .then((response) => {
          if (response.status_code === 201) resolve(response);
          else {
            reject(response);
            toastService.error(response.message);
          }
        })
        .catch((error) => {
          reject(error);
          toastService.error("Something went wrong. Please try again later.");
        });
    });

  const onSubmit = async (values) => {
    setShowLoader(true);
    let params = {
      name: values.product_name,
      description: values.description,
      category_list: values.category_list,
      // tax_id: values.tax,
      image_id: values.image_id,
      thumb_image_id: values.thumb_image_id
    };

    if (values.imgFile) {
      await uploadFiles(values.imgFile)
        .then((response) => {
          params.image_id = response.payload.file_id;
        })
        .catch((error) => { });
    }
    if (values.thumbImgFile) {
      await uploadFiles(values.thumbImgFile)
        .then((response) => {
          params.thumb_image_id = response.payload.file_id;
        })
        .catch((error) => { });
    }

    if (rowData) {
      params.id = rowData.id;
      updateItem(params);
    } else {
      createItem(params);
    }
  };

  const createItem = (params) => {
    invokeApi("post", ApiConstants.products.create, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 201) {
          closeModal();
          getProducts();
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const updateItem = (params) => {
    invokeApi("put", ApiConstants.products.update, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          closeModal();
          getProducts();
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const deleteItem = () => {
    setShowLoader(true);
    let params = { id: rowData.id };
    invokeApi("delete", ApiConstants.products.delete, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          closeModal();
          getProducts();
          toastService.success(response.message);
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        closeModal();
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const onEdit = (data) => {
    setRowData(data);
    setShowForm(true);
  };

  const onDelete = (data) => {
    setRowData(data);
    setshowConfirmDelete(true);
  };

  const closeModal = () => {
    setRowData(null);
    setShowForm(false);
    setshowConfirmDelete(false);
    setIsSubmitted(false);
  };

  const getCategoriesList = () => {
    invokeApi("get", ApiConstants.categories.get, null, false)
      .then((response) => {
        if (response.status_code === 200) {
          setCategoriesList(
            response.payload?.filter(i => i.category_id !== 1).map((i) => {
              return { ...i, value: i.category_id, label: i.category_name };
            })
          );
        } else {
          setShowLoader(false);
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Products</h4>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body px-1 px-lg-3">
          <div className="row mb-2">
            <div className="col-12 mb-1 text-end">
              <button
                type="button"
                className={`btn waves-effect waves-light me-2 ${!enableFilter ? "btn-outline-success" : "btn-success"
                  }`}
                onClick={() => {
                  setEnableFilter(!enableFilter);
                }}>
                <i
                  className={`mdi ${enableFilter ? "mdi-filter-off" : "mdi-filter"
                    }`}></i>
              </button>
              <button
                type="button"
                className="btn btn-success waves-effect waves-light"
                onClick={() => {
                  setShowForm(true);
                }}>
                <span className="btn-label">
                  <i className="mdi mdi-plus"></i>
                </span>
                Add Products
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DataGrid
                gridData={gridData}
                columns={columns}
                pagerSettings={pagerSettings}
                skip={0}
                take={5}
                filterable={enableFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={showForm} backdrop="static" keyboard={true}>
        <Modal.Header>
          <h4 className="modal-title">{`${rowData ? "Update" : "Add"
            } Product`}</h4>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}></button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              product_name: rowData?.name ? rowData.name : "",
              category_list: rowData?.categories ? rowData.categories.map(i => i.id) : [],
              description: rowData?.description
                ? rowData.description
                : "",
              imgFile: undefined,
              thumbImgFile: undefined,
              image_id: rowData?.image_id ? rowData.image_id : null,
              thumb_image_id: rowData?.thumb_image_id ? rowData.thumb_image_id : null,
            }}
            validationSchema={FormSchema}
            onSubmit={(values) => onSubmit(values)}>
            {({ errors, setFieldValue, handleChange, values }) => (
              <Form>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <label>Product Name</label>
                      <Field
                        type="text"
                        className={`form-control ${errors.product_name && isSubmitted ? "is-invalid" : ""
                          }`}
                        placeholder="Product Name"
                        name="product_name"
                      />
                      <ErrorMessage name="product_name">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 mt-2">
                      <label>Categories</label>
                      <Select
                        className={`w-100 form-control-select ${errors.category_list && isSubmitted ? "is-invalid" : ""
                          }`}
                        classNamePrefix="select"
                        options={categoriesList}
                        placeholder="Select Categories"
                        isSearchable
                        isMulti
                        styles={{ option: (base) => ({ ...base, zIndex: 100 }) }}
                        defaultValue={categoriesList.filter(i => values.category_list.includes(i.category_id))}
                        onChange={(value) => {
                          let event = {
                            target: {
                              name: "category_list",
                              value: value.map((i) => i.category_id),
                            },
                          };
                          handleChange(event);
                        }}
                      />
                      <ErrorMessage name="category_list">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-6 mt-2">
                      <label>Image</label>
                      <div className="input-group">
                        <input
                          id="imgFile"
                          className={`form-control ${errors.imgFile && isSubmitted ? "is-invalid" : ""
                            }`}
                          type="file"
                          onChange={(event) => {
                            setFieldValue(
                              "imgFile",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage name="imgFile">
                          {(msg) => (
                            <div className="invalid-feedback">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      {rowData && rowData?.image && (
                        <div className="mt-1">
                          <a
                            className="text-blue"
                            target="_blank"
                            rel="noreferrer"
                            href={viewFile(rowData?.image.file_path)}>
                            Download
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="col-6 mt-2">
                      <label>Thumbnail Image</label>
                      <div className="input-group">
                        <input
                          id="imgFile"
                          className={`form-control ${errors.thumbImgFile && isSubmitted ? "is-invalid" : ""
                            }`}
                          type="file"
                          onChange={(event) => {
                            setFieldValue(
                              "thumbImgFile",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage name="thumbImgFile">
                          {(msg) => (
                            <div className="invalid-feedback">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      {rowData && rowData?.thumb_image && (
                        <div className="mt-1">
                          <a
                            className="text-blue"
                            target="_blank"
                            rel="noreferrer"
                            href={viewFile(rowData?.thumb_image.file_path)}>
                            Download
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="col-12 mt-2">
                      <label>Description</label>
                      <textarea
                        className={`form-control ${errors.description && isSubmitted ? "is-invalid" : ""
                          }`}
                        placeholder="Description"
                        value={values.description}
                        onChange={(event) => {
                          setFieldValue("description", event.target.value);
                        }}
                      />
                      <ErrorMessage name="description">
                        {(msg) => <div className="invalid-feedback">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 my-2 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary waves-effect waves-light mt-3 me-2"
                        onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-blue waves-effect waves-light mt-3"
                        onClick={() => setIsSubmitted(true)}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showConfirmDelete}
        backdrop="static"
        keyboard={true}>
        <Modal.Header>
          <h5 className="card-title m-0">Confirm Action</h5>
        </Modal.Header>
        <Modal.Body>
          <div> Are you sure that to delete this? </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-outline-primary me-2"
              onClick={closeModal}>
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                deleteItem();
              }}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Products;
