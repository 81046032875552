import React, { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { invokeApi } from "../../services/apiService";
import { toastService } from "../../services/toastService";
import ApiConstants from "../../config/apiConstants";
import Spinner from "../../components/spinner";

const Settings = () => {

  const [showLoader, setShowLoader] = useState(false);
  const [isCustomerAppFormSubmitted, setIsCustomerAppFormSubmitted] = useState(false);
  const [customerAppSettings, setCustomerAppSettings] = useState(null);
  const [isVendorAppFormSubmitted, setIsVendorAppFormSubmitted] = useState(false);
  const [vendorApppSettings, setVendorApppSettings] = useState(null);

  let CustomerForm = Yup.object().shape({
    android_latest_version: Yup.string().required("This field is required."),
    android_min_supported_version: Yup.string().required("This field is required."),
    android_forceful_update_message: Yup.string().required("This field is required."),
    android_recommended_update_message: Yup.string().required("This field is required."),
    ios_latest_version: Yup.string().required("This field is required."),
    ios_min_supported_version: Yup.string().required("This field is required."),
    ios_forceful_update_message: Yup.string().required("This field is required."),
    ios_recommended_update_message: Yup.string().required("This field is required."),
  });

  let VendorForm = Yup.object().shape({
    android_latest_version: Yup.string().required("This field is required."),
    android_min_supported_version: Yup.string().required("This field is required."),
    android_forceful_update_message: Yup.string().required("This field is required."),
    android_recommended_update_message: Yup.string().required("This field is required."),
    ios_latest_version: Yup.string().required("This field is required."),
    ios_min_supported_version: Yup.string().required("This field is required."),
    ios_forceful_update_message: Yup.string().required("This field is required."),
    ios_recommended_update_message: Yup.string().required("This field is required."),
  });

  useEffect(() => {
    getCustomerAppSettings();
    getVendorAppSettings();
  }, [])

  const getCustomerAppSettings = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.settings.getCustomerAppSettings, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setCustomerAppSettings(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const getVendorAppSettings = () => {
    setShowLoader(true);
    invokeApi("get", ApiConstants.settings.getVendorAppSettings, null, true)
      .then((response) => {
        if (response.status_code === 200) {
          setVendorApppSettings(response.payload);
        } else {
          toastService.error(response.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  };

  const onCustomerAppSettingsFormSubmit = (values) => {
    setShowLoader(true);
    const { android_latest_version, android_min_supported_version, android_forceful_update_message, android_recommended_update_message, ios_latest_version, ios_min_supported_version, ios_forceful_update_message, ios_recommended_update_message, } = values;

    let params = {
      android_latest_version,
      android_min_supported_version,
      android_forceful_update_message,
      android_recommended_update_message,
      ios_latest_version,
      ios_min_supported_version,
      ios_forceful_update_message,
      ios_recommended_update_message,
    };

    invokeApi("post", ApiConstants.settings.setCustomerAppSettings, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          toastService.success(response.message);
          getCustomerAppSettings();
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  }

  const onVendorAppSettingsFormSubmit = (values) => {
    setShowLoader(true);
    const { android_latest_version, android_min_supported_version, android_forceful_update_message, android_recommended_update_message, ios_latest_version, ios_min_supported_version, ios_forceful_update_message, ios_recommended_update_message, } = values;

    let params = {
      android_latest_version,
      android_min_supported_version,
      android_forceful_update_message,
      android_recommended_update_message,
      ios_latest_version,
      ios_min_supported_version,
      ios_forceful_update_message,
      ios_recommended_update_message,
    };

    invokeApi("post", ApiConstants.settings.setVendorAppSettings, params, true)
      .then((response) => {
        setShowLoader(false);
        if (response && response.status_code === 200) {
          toastService.success(response.message);
          getVendorAppSettings();
        } else {
          toastService.error(response.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toastService.error("Something went wrong. Please try again later.");
      });
  }

  return (
    <React.Fragment>
      {showLoader && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title">Settings</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 stretch-card settings-container">
          <Tabs
            defaultActiveKey="customerApp"
            id="app-tabs"
          >
            <Tab eventKey="customerApp" title="Customer App">
              <div className="card">
                <div className="card-body p-4">
                  <h4 className="mb-1">Customer App Settings</h4>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      android_latest_version: customerAppSettings?.android_latest_version ? customerAppSettings.android_latest_version : "",
                      android_min_supported_version: customerAppSettings?.android_min_supported_version ? customerAppSettings.android_min_supported_version : "",
                      android_recommended_update_message: customerAppSettings?.android_recommended_update_message ? customerAppSettings.android_recommended_update_message : "",
                      android_forceful_update_message: customerAppSettings?.android_forceful_update_message ? customerAppSettings.android_forceful_update_message : "",
                      ios_latest_version: customerAppSettings?.ios_latest_version ? customerAppSettings.ios_latest_version : "",
                      ios_min_supported_version: customerAppSettings?.ios_min_supported_version ? customerAppSettings.ios_min_supported_version : "",
                      ios_recommended_update_message: customerAppSettings?.ios_recommended_update_message ? customerAppSettings.ios_recommended_update_message : "",
                      ios_forceful_update_message: customerAppSettings?.ios_forceful_update_message ? customerAppSettings.ios_forceful_update_message : "",
                    }}
                    validationSchema={CustomerForm}
                    onSubmit={(values) => onCustomerAppSettingsFormSubmit(values)}>
                    {({ errors, setFieldValue, values }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_latest_version" className="form-label">
                              Android latest version
                            </label>
                            <Field
                              className={`form-control ${errors.android_latest_version && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="android_latest_version"
                              placeholder="Android latest version"
                            />
                            <ErrorMessage name="android_latest_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_latest_version" className="form-label">
                              iOS latest version
                            </label>
                            <Field
                              className={`form-control ${errors.ios_latest_version && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="ios_latest_version"
                              placeholder="iOS latest version"
                            />
                            <ErrorMessage name="ios_latest_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_min_supported_version" className="form-label">
                              Android min supported version
                            </label>
                            <Field
                              className={`form-control ${errors.android_min_supported_version && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="android_min_supported_version"
                              placeholder="Android min supported version"
                            />
                            <ErrorMessage name="android_min_supported_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_min_supported_version" className="form-label">
                              iOS min supported version
                            </label>
                            <Field
                              className={`form-control ${errors.ios_min_supported_version && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="ios_min_supported_version"
                              placeholder="iOS min supported version"
                            />
                            <ErrorMessage name="ios_min_supported_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_recommended_update_message" className="form-label">
                              Android recommended update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.android_recommended_update_message && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="Android recommended update message"
                              value={values.android_recommended_update_message}
                              onChange={(event) => {
                                setFieldValue("android_recommended_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="android_recommended_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_recommended_update_message" className="form-label">
                              iOS recommended update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.ios_recommended_update_message && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="iOS recommended update message"
                              value={values.ios_recommended_update_message}
                              onChange={(event) => {
                                setFieldValue("ios_recommended_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="ios_recommended_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_forceful_update_message" className="form-label">
                              Android forceful update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.android_forceful_update_message && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="Android forceful update message"
                              value={values.android_forceful_update_message}
                              onChange={(event) => {
                                setFieldValue("android_forceful_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="android_forceful_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_forceful_update_message" className="form-label">
                              iOS recommended update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.ios_forceful_update_message && isCustomerAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="iOS recommended update message"
                              value={values.ios_forceful_update_message}
                              onChange={(event) => {
                                setFieldValue("ios_forceful_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="ios_forceful_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 text-end mt-3">
                            <button
                              className="btn btn-primary auth-btn"
                              type="submit"
                              onClick={() => setIsCustomerAppFormSubmitted(true)}>
                              Save Customer App Settings
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Tab>
            <Tab eventKey="vendorApp" title="Vendor App">
            <div className="card">
                <div className="card-body p-4">
                  <h4 className="mb-1">Vendor App Settings</h4>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      android_latest_version: vendorApppSettings?.android_latest_version ? vendorApppSettings.android_latest_version : "",
                      android_min_supported_version: vendorApppSettings?.android_min_supported_version ? vendorApppSettings.android_min_supported_version : "",
                      android_recommended_update_message: vendorApppSettings?.android_recommended_update_message ? vendorApppSettings.android_recommended_update_message : "",
                      android_forceful_update_message: vendorApppSettings?.android_forceful_update_message ? vendorApppSettings.android_forceful_update_message : "",
                      ios_latest_version: vendorApppSettings?.ios_latest_version ? vendorApppSettings.ios_latest_version : "",
                      ios_min_supported_version: vendorApppSettings?.ios_min_supported_version ? vendorApppSettings.ios_min_supported_version : "",
                      ios_recommended_update_message: vendorApppSettings?.ios_recommended_update_message ? vendorApppSettings.ios_recommended_update_message : "",
                      ios_forceful_update_message: vendorApppSettings?.ios_forceful_update_message ? vendorApppSettings.ios_forceful_update_message : "",
                    }}
                    validationSchema={VendorForm}
                    onSubmit={(values) => onVendorAppSettingsFormSubmit(values)}>
                    {({ errors, setFieldValue, values }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_latest_version" className="form-label">
                              Android latest version
                            </label>
                            <Field
                              className={`form-control ${errors.android_latest_version && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="android_latest_version"
                              placeholder="Android latest version"
                            />
                            <ErrorMessage name="android_latest_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_latest_version" className="form-label">
                              iOS latest version
                            </label>
                            <Field
                              className={`form-control ${errors.ios_latest_version && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="ios_latest_version"
                              placeholder="iOS latest version"
                            />
                            <ErrorMessage name="ios_latest_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_min_supported_version" className="form-label">
                              Android min supported version
                            </label>
                            <Field
                              className={`form-control ${errors.android_min_supported_version && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="android_min_supported_version"
                              placeholder="Android min supported version"
                            />
                            <ErrorMessage name="android_min_supported_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_min_supported_version" className="form-label">
                              iOS min supported version
                            </label>
                            <Field
                              className={`form-control ${errors.ios_min_supported_version && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              type="text"
                              name="ios_min_supported_version"
                              placeholder="iOS min supported version"
                            />
                            <ErrorMessage name="ios_min_supported_version">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_recommended_update_message" className="form-label">
                              Android recommended update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.android_recommended_update_message && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="Android recommended update message"
                              value={values.android_recommended_update_message}
                              onChange={(event) => {
                                setFieldValue("android_recommended_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="android_recommended_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_recommended_update_message" className="form-label">
                              iOS recommended update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.ios_recommended_update_message && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="iOS recommended update message"
                              value={values.ios_recommended_update_message}
                              onChange={(event) => {
                                setFieldValue("ios_recommended_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="ios_recommended_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="android_forceful_update_message" className="form-label">
                              Android forceful update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.android_forceful_update_message && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="Android forceful update message"
                              value={values.android_forceful_update_message}
                              onChange={(event) => {
                                setFieldValue("android_forceful_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="android_forceful_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-5 col-12 mt-3">
                            <label htmlFor="ios_forceful_update_message" className="form-label">
                              iOS recommended update message
                            </label>
                            <textarea
                              className={`form-control update-msgs-textbox ${errors.ios_forceful_update_message && isVendorAppFormSubmitted ? "is-invalid" : ""
                                }`}
                              placeholder="iOS recommended update message"
                              value={values.ios_forceful_update_message}
                              onChange={(event) => {
                                setFieldValue("ios_forceful_update_message", event.target.value);
                              }}
                            />
                            <ErrorMessage name="ios_forceful_update_message">
                              {(msg) => (
                                <div className="invalid-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 text-end mt-3">
                            <button
                              className="btn btn-primary auth-btn"
                              type="submit"
                              onClick={() => setIsVendorAppFormSubmitted(true)}>
                              Save Vendor App Settings
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Settings;
