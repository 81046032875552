import axios from "axios";

import AppConfig from "../config/appConfig";
import ApiConstants from "../config/apiConstants";

export const invokeApi = (method, url, params, auth) => {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {},
  };

  if (auth) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  let axiosConfig = {
    method,
    url: AppConfig.API_BASE_URL + url,
    headers: config.headers,
  };

  if (method.toUpperCase() === "GET") {
    axiosConfig.params = params;
  } else {
    axiosConfig.data = params;
  }

  const getRefreshToken = () => {
    return new Promise((resolve, reject) => {
      let axiosConfig = {
        method: "get",
        url: AppConfig.API_BASE_URL + ApiConstants.auth.refreshtoken,
        headers: config.headers,
      };
      axios(axiosConfig)
        .then((response) => {
          if (response.data) {
            resolve(response?.data?.payload);
          } else {
            reject(response);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const retry = (newToken, resolve, reject) => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    userObj.token = newToken;
    localStorage.setItem("user", JSON.stringify(userObj));
    axiosConfig.headers.Authorization = `Bearer ${newToken}`;

    axios(axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  };

  return new Promise((resolve, reject) => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          return { status: 401 };
        } else {
          return error;
        }
      }
    );

    axios(axiosConfig)
      .then((response) => {
        if (response && response.status === 401) {
          getRefreshToken()
            .then((refreshtoken) => {
              retry(refreshtoken, resolve, reject);
            })
            .catch((e) => {
              localStorage.clear();
              window.location.reload();
            });
        } else {
          resolve(response.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const invokeFileUpload = (params) => {
  const config = {
    headers: {},
  };

  let axiosConfig = {
    method: "post",
    url: AppConfig.API_BASE_URL + ApiConstants.file.upload,
    headers: config.headers,
  };

  axiosConfig.data = params;

  return new Promise((resolve, reject) => {
    axios(axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const viewFile = (fileName) => {
  return (
    AppConfig.API_BASE_URL + ApiConstants.file.view + "?file_name=" + fileName
  );
};
