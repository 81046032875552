import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { viewFile } from "../../services/apiService";

const AppHeader = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    setUserData(userObj);
  }, []);

  const logout = (event) => {
    event.preventDefault();
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <div className="navbar-custom">
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          <li className="dropdown d-none d-lg-inline-block">
            <Link
              className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
              data-toggle="fullscreen"
              to="">
              <i className="fe-maximize noti-icon"></i>
            </Link>
          </li>

          <li className="dropdown notification-list topbar-dropdown">
            <Link
              className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false">
              <img
                src={
                  userData?.user_details?.profileImage
                    ? viewFile(userData?.user_details?.profileImage)
                    : "/assets/images/avatar.jpg "
                }
                alt="user-dp"
                className="rounded-circle"
              />
              <span className="pro-user-name ms-2">
                {userData?.user_details?.name}
                <i className="mdi mdi-chevron-down"></i>
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
              <Link to="/profile" className="dropdown-item notify-item">
                <i className="fe-user"></i>
                <span>Profile</span>
              </Link>

              <Link to="/change-password" className="dropdown-item notify-item">
                <i className="fe-settings"></i>
                <span>Change Password</span>
              </Link>

              <div className="dropdown-divider"></div>

              <Link
                to=""
                className="dropdown-item notify-item"
                onClick={logout}>
                <i className="fe-log-out"></i>
                <span>Logout</span>
              </Link>
            </div>
          </li>
        </ul>

        <div className="logo-box">
          <Link to="/dashboard" className="logo logo-dark text-center">
            <span className="logo-sm">
              <img src="/assets/images/logo.png" alt="" height="35" />
            </span>
            <span className="logo-lg">
              <img src="/assets/images/logo.png" alt="" height="45" />
            </span>
          </Link>

          <Link to="/dashboard" className="logo logo-light text-center">
            <span className="logo-sm">
              <img src="/assets/images/logo-small.png" alt="" height="35" />
            </span>
            <span className="logo-lg">
              <img src="/assets/images/logo.png" alt="" height="55" />
            </span>
          </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button className="button-menu-mobile waves-effect waves-light">
              <i className="fe-menu"></i>
            </button>
          </li>

          <li>
            <Link
              className="navbar-toggle nav-link"
              to=""
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content">
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </li>
        </ul>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default AppHeader;
