import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

const ADJUST_PADDING = 0;
const COLUMN_MIN = 4;

const DataGrid = (props) => {
  const minGridWidth = React.useRef(0);
  const grid = React.useRef(null);
  const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);
  const [gridState, setgridState] = React.useState({
    skip: props.skip,
    take: props.take,
  });
  const [gridData, setgridData] = React.useState(null);

  React.useEffect(() => {
    loadGridData();
    // eslint-disable-next-line
  }, [gridState, props.gridData]);

  React.useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);

    let resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(document.querySelector(".k-grid"));

    props.columns.map((item) =>
      item.minWidth !== undefined
        ? (minGridWidth.current += item.minWidth)
        : minGridWidth.current
    );
    if (grid.current.offsetWidth) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
    }

    return () => {
      setApplyMinWidth(false);
      setGridCurrent(0);
    };
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    let totalWidth = props.columns.reduce(
      (accum, item) => accum + (item.width || item.minWidth || 0),
      0
    );
    if (grid.current.offsetWidth < totalWidth && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current.offsetWidth > totalWidth) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(false);
    }
  };

  const setWidth = (minWidth) => {
    let variableColumns = props.columns.filter((i) => i.minWidth);
    let fixedWidth = props.columns.reduce(
      (accum, item) => accum + (item.width || 0),
      0
    );
    minWidth = minWidth === undefined ? 0 : minWidth;
    let width = applyMinWidth
      ? minWidth
      : minWidth +
      (gridCurrent -
        minGridWidth.current -
        (fixedWidth - ADJUST_PADDING || 0)) /
      variableColumns?.length;
    width = width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
    if (applyMinWidth) width = width < minWidth ? minWidth : width;
    return width;
  };

  const pageChange = (event) => {
    let updatedState = {
      ...gridState,
      skip: event.page.skip,
      take: event.page.take,
    };
    setgridState({ ...updatedState });
  };

  const filterChange = (event) => {
    let updatedState = {
      ...gridState,
      filter: event.filter,
    };
    setgridState(updatedState);
  };

  const loadGridData = () => {
    let updatedData = process(props.gridData, gridState);
    setgridData(updatedData);
  };

  return (
    <div>
      <Grid
        data={gridData}
        skip={gridState.skip}
        pageSize={gridState.take}
        pageable={props.pagerSettings}
        onPageChange={pageChange}
        filterable={props.filterable}
        filter={gridState.filter}
        onFilterChange={filterChange}>
        {props.columns.map((column, index) => {
          return (
            <Column
              key={index}
              {...column}
              width={column.width ? column.width : setWidth(column.minWidth)}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default DataGrid;
