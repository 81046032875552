import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { viewFile } from "../../services/apiService";

const AppSideBar = () => {
  const [userData, setUserData] = useState(null);
  const [currentPath, setCurrentPath] = useState("");

  const location = useLocation();
  React.useEffect(() => {
    setCurrentPath(location?.pathname);
  }, [location]);

  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    setUserData(userObj);
  }, []);

  const isActiveMenu = (menu) => {
    return currentPath.includes(menu) ? "menuitem-active" : null;
  };

  return (
    <div className="left-side-menu">
      <div className="h-100" data-simplebar>
        <div className="user-box mb-2">
          <img
            src={
              userData?.user_details?.profileImage
                ? viewFile(userData?.user_details?.profileImage)
                : "/assets/images/avatar.jpg"
            }
            alt="user-dp"
            className="rounded-circle avatar-md"
          />
          <div className="ms-2">
            <NavLink
              to=""
              className="text-dark dropdown-toggle h5">
              {userData?.user_details?.name}
            </NavLink>
            <div className="text-muted">{userData?.user_details?.role}</div>
          </div>
        </div>

        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className={isActiveMenu("/dashboard")}>
              <NavLink to="/dashboard">
                <i data-feather="home"></i>
                <span> Dashboard </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/amenities")}>
              <NavLink to="/amenities">
                <i data-feather="anchor"></i>
                <span> Amenities </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/services")}>
              <NavLink to="/services">
                <i data-feather="package"></i>
                <span> Services </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/orders")}>
              <NavLink to="/orders">
                <i data-feather="layers"></i>
                <span> Orders </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/categories")}>
              <NavLink to="/categories">
                <i data-feather="folder"></i>
                <span> Categories </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/products")}>
              <NavLink to="/products">
                <i data-feather="shield"></i>
                <span> Products </span>
              </NavLink>
            </li>
            
            <li className={isActiveMenu("/settings")}>
              <NavLink to="/settings">
                <i data-feather="settings"></i>
                <span> Settings </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/users")}>
              <a href="#sidebarUsers" data-bs-toggle="collapse">
                <i data-feather="users"></i>
                <span> User Management </span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="sidebarUsers">
                <ul className="nav-second-level">
                  <li>
                    <NavLink to="/users/customers">Customers</NavLink>
                  </li>
                  <li>
                    <NavLink to="/users/vendors">Vendors</NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li className={isActiveMenu("/tax")}>
              <NavLink to="/tax">
                <i data-feather="hexagon"></i>
                <span> Taxes </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/banners")}>
              <NavLink to="/banners">
                <i data-feather="activity"></i>
                <span> Banners </span>
              </NavLink>
            </li>

            <li className={isActiveMenu("/countries")}>
              <NavLink to="/countries">
                <i data-feather="flag"></i>
                <span> Countries </span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default AppSideBar;
