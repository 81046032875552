import React, { useEffect } from "react";

import AppHeader from "../components/header";
import AppSideBar from "../components/sidebar";
import Footer from "../components/footer";

const AdminLayout = ({ children }) => {
  useEffect(() => {
    appendScript(process.env.PUBLIC_URL + "/assets/js/app.js");

    document.body.setAttribute("data-sidebar-color", "dark");
    document.body.setAttribute("data-sidebar-showuser", "true");

    return () => {
      let elem = document.getElementById("app-js");
      elem.parentNode.removeChild(elem);
    };
  }, []);

  const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    script.id = "app-js";
    script.defer = true;
    document.body.appendChild(script);
  };

  return (
    <React.Fragment>
      <div id="wrapper">
        <AppHeader />
        <AppSideBar />
        <div className="content-page px-0 px-lg-2">
          <div className="content">
            <div className="container-fluid">{children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminLayout;
